var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',{attrs:{"no-body":""}},[_c('table-header',{attrs:{"per-page-options":_vm.perPageOptions},scopedSlots:_vm._u([{key:"button",fn:function(){return [(_vm.$can('create', _vm.PERMISSION_INSURED_USERS))?_c('b-button',{staticClass:"ml-1",attrs:{"variant":"primary","to":{ name: 'insured-create' }}},[_vm._v(" Crear Asegurado ")]):_vm._e(),(_vm.$can('update', _vm.PERMISSION_INSURED_USERS))?_c('b-button',{staticClass:"ml-1",attrs:{"variant":"primary","to":{ name: 'insured-enabled' }}},[_vm._v(" Alta de Asegurado ")]):_vm._e()]},proxy:true}])}),_c('b-table',{ref:"refTable",staticClass:"position-relative",attrs:{"items":_vm.fetchItems,"fields":_vm.tableColumns,"sort-by":_vm.sortBy,"sort-desc":_vm.isSortDirDesc,"show-empty":"","empty-text":"No se encontraron resultados","responsive":"","primary-key":"id"},on:{"update:sortBy":function($event){_vm.sortBy=$event},"update:sort-by":function($event){_vm.sortBy=$event},"update:sortDesc":function($event){_vm.isSortDirDesc=$event},"update:sort-desc":function($event){_vm.isSortDirDesc=$event}},scopedSlots:_vm._u([{key:"cell(actions)",fn:function(data){return [_c('div',{staticClass:"text-nowrap"},[_c('router-link',{attrs:{"to":{
            name: 'insured-edit',
            params: { id: data.item.id, relationship_type_id: data.item.relationship_type_id  },
          }}},[_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:('Editar Asegurado'),expression:"'Editar Asegurado'",modifiers:{"hover":true,"top":true}}],staticClass:"btn-icon rounded-circle",attrs:{"variant":"flat-success"}},[_c('feather-icon',{attrs:{"icon":"EditIcon"}})],1)],1),_c('router-link',{attrs:{"to":{
            name: 'insured-disabled',
            params: { id: data.item.id },
          }}},[(data.item.relationship_type_id !== 39)?_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:('Baja solo del dependiente'),expression:"'Baja solo del dependiente'",modifiers:{"hover":true,"top":true}}],staticClass:"btn-icon rounded-circle",attrs:{"variant":"flat-warning"}},[_c('feather-icon',{attrs:{"icon":"ArrowDownIcon"}})],1):_vm._e()],1),_c('router-link',{attrs:{"to":{
            name: 'insured-disabled',
            params: { id: data.item.id },
          }}},[(data.item.relationship_type_id === 39)?_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:('Baja del titular y sus dependientes'),expression:"'Baja del titular y sus dependientes'",modifiers:{"hover":true,"top":true}}],staticClass:"btn-icon rounded-circle",attrs:{"variant":"flat-danger"}},[_c('feather-icon',{attrs:{"icon":"ArrowDownIcon"}})],1):_vm._e()],1),_c('router-link',{attrs:{"to":{
          name: 'dependent-create-admin',
          params: { id: data.item.user_id},

        }}},[(data.item.id === 31)?_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:('Crear Dependiente'),expression:"'Crear Dependiente'",modifiers:{"hover":true,"top":true}}],staticClass:"btn-icon rounded-circle",attrs:{"variant":"flat-danger"}},[_c('feather-icon',{attrs:{"icon":"UserIcon"}})],1):_vm._e()],1),_c('router-link',{attrs:{"to":{
          name: 'insured-appointment-list',
          params: { userId: data.item.user_id}
        }}},[_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:('Citas Medicas'),expression:"'Citas Medicas'",modifiers:{"hover":true,"top":true}}],staticClass:"btn-icon ",attrs:{"variant":"flat-warning"}},[_c('feather-icon',{attrs:{"icon":"CalendarIcon"}})],1)],1)],1)]}},{key:"cell(user.birth_date)",fn:function(data){return [_c('strong',[_vm._v(_vm._s(_vm._f("formatDateSimple")(data.item.user.birth_date)))])]}},{key:"cell(relationship)",fn:function(data){return [_vm._v(" "+_vm._s(data.item.key)+" ")]}}])}),_c('table-pagination',{attrs:{"total-rows":_vm.totalRows,"per-page":_vm.perPage}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }